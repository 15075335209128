import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "./Pricing.css";
import PricingForm from "./PricingForm";

const Pricing = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    package: "",
    timestamp: serverTimestamp(),
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const packages = [
    {
      title: "Starter Website",
      description:
        "An affordable, fast, and professional website for new businesses looking to establish an online presence.",
      features: [
        "1-3 pages (Home, About, Contact)",
        "Mobile-friendly, responsive design",
        "Basic SEO setup to help customers find you",
        "Delivered in one week",
        "Easy to update content",
      ],
      price: "$799*",
      duration: "One-time payment",
      deliveryTime: "1 Week",
      callToAction:
        "Perfect for businesses needing a quick and professional online presence!",
      icon: "🌟",
    },
    {
      title: "Growth Website",
      description:
        "A flexible and functional website designed to support business growth through added features like blogs or simple e-commerce.",
      features: [
        "Up to 5 pages (e.g., Home, About, Services, Blog, Contact)",
        "Blog setup or basic e-commerce functionality",
        "Mobile-friendly, responsive design",
        "SEO setup to boost visibility",
        "Delivered in 1–2 weeks",
      ],
      price: "$1,499*",
      duration: "One-time payment",
      deliveryTime: "1–2 Weeks",
      callToAction:
        "Ideal for growing businesses looking to expand their reach and functionality online!",
      icon: "📈",
    },
    {
      title: "Premium Website",
      description:
        "A tailor-made website with advanced features and custom designs for businesses ready to scale and stand out.",
      features: [
        "Completely custom design to match your brand",
        "Advanced functionality (e.g., booking systems, memberships, integrations)",
        "Mobile-friendly, responsive design",
        "Comprehensive SEO setup to dominate search results",
        "Delivered in 2–3 weeks",
        "Post-launch support for smooth transition",
      ],
      price: "$2,499*",
      duration: "One-time payment",
      deliveryTime: "2–3 Weeks",
      callToAction:
        "The perfect choice for businesses ready to make a bold statement online!",
      icon: "💎",
    },
  ];

  // const packages = [
  //   {
  //     title: "Starter Website",
  //     description:
  //       "An affordable, fast, and professional website for new businesses looking to establish an online presence.",
  //     features: [
  //       "1-3 pages (Home, About, Contact)",
  //       "Mobile-friendly, responsive design",
  //       "Basic SEO setup to help customers find you",
  //       "Delivered in one week",
  //       "Easy to update content",
  //     ],
  //     price: "$799",
  //     duration: "One-time payment",
  //     icon: "🌟",
  //   },
  //   {
  //     title: "Growth Website",
  //     description:
  //       "A flexible and functional website designed to support business growth through added features like blogs or simple e-commerce.",
  //     features: [
  //       "Up to 5 pages (e.g., Home, About, Services, Blog, Contact)",
  //       "Blog setup or basic e-commerce functionality",
  //       "Mobile-friendly, responsive design",
  //       "SEO setup to boost visibility",
  //       "Delivered in one week",
  //     ],
  //     price: "$1,499",
  //     duration: "One-time payment",
  //     icon: "📈",
  //   },
  //   {
  //     title: "Premium Website",
  //     description:
  //       "A tailor-made website with advanced features and custom designs for businesses ready to scale and stand out.",
  //     features: [
  //       "Completely custom design to match your brand",
  //       "Advanced functionality (e.g., booking systems, memberships, integrations)",
  //       "Mobile-friendly, responsive design",
  //       "Comprehensive SEO setup to dominate search results",
  //       "Delivered in one week",
  //       "Post-launch support for smooth transition",
  //     ],
  //     price: "$2,499",
  //     duration: "One-time payment",
  //     icon: "💎",
  //   },
  // ];

  const testimonials = [
    {
      name: "Jane Doe",
      feedback:
        "The team delivered a fantastic website in just one week! Our customers love it, and it's already driving more traffic.",
    },
    {
      name: "John Smith",
      feedback:
        "Innonative Solutions provided us with exactly what we needed. The ROI on our website has been incredible.",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.package) {
      alert("Please select a package before submitting.");
      return;
    }

    try {
      await addDoc(collection(db, "leads"), {
        ...formData,
        timestamp: serverTimestamp(),
      });
      setFormSubmitted(true);
      setFormData({
        name: "",
        email: "",
        message: "",
        package: "",
      });
    } catch (error) {
      console.error("Error saving lead: ", error);
      alert("There was an error submitting your inquiry. Please try again.");
    }
  };

  return (
    <div className="page-transition">
      <div className="pricing-page">
        <header className="pricing-header">
          <h1>Pricing</h1>
          <p>Simple, clear offerings designed to help your business grow.</p>
        </header>

        <div className="pricing-container">
          {packages.map((pkg, index) => (
            <div
              key={index}
              className={`pricing-card ${index === 1 ? "popular-plan" : ""}`}
              onClick={() => setSelectedPackage(pkg.title)}
            >
              <div className="icon">{pkg.icon}</div>
              <h2>{pkg.title}</h2>
              <p className="description">{pkg.description}</p>
              <div className="price">{pkg.price}</div>
              <p className="plan-duration">{pkg.duration}</p>
              <ul className="features">
                {pkg.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
              <p className="call-to-action">{pkg.callToAction}</p>{" "}
              {/* Add Call to Action */}
              {/* <button
                className="cta-button"
                onClick={() =>
                  setFormData((prev) => ({ ...prev, package: pkg.title }))
                }
              >
                Select {pkg.title}
              </button> */}
            </div>
          ))}
        </div>
        <div className="disclaimer">
          <p className="price-disclaimer">
            *Price may vary depending on special requests or added features.
          </p>
        </div>

        {/* <section className="trust-section">
          <h2>What Our Clients Say</h2>
          <div className="testimonials">
            {testimonials.map((testimonial, i) => (
              <blockquote key={i} className="testimonial">
                <p>"{testimonial.feedback}"</p>
                <footer>- {testimonial.name}</footer>
              </blockquote>
            ))}
          </div>
        </section> */}

        <PricingForm></PricingForm>

        <section className="faq-section">
          <h2>FAQs</h2>
          <p className="faq-intro">
            Have questions about our packages or services? Check out some of the
            most frequently asked questions below.
          </p>

          <details>
            <summary>What’s included in each package?</summary>
            <p>
              Each package is tailored to meet specific needs, from small
              startups to businesses ready for a premium online presence.
            </p>
            <table className="comparison-table">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Starter</th>
                  <th>Growth</th>
                  <th>Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pages</td>
                  <td>1-3</td>
                  <td>Up to 5</td>
                  <td>Custom</td>
                </tr>
                <tr>
                  <td>SEO</td>
                  <td>Basic</td>
                  <td>Standard</td>
                  <td>Advanced</td>
                </tr>
                <tr>
                  <td>Delivery Time</td>
                  <td>1 Week</td>
                  <td>1-2 Weeks</td>
                  <td>2-3 Weeks</td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>Email</td>
                  <td>Email + Chat</td>
                  <td>Priority Support</td>
                </tr>
                <tr>
                  <td>Hosting Setup</td>
                  <td>Basic Assistance</td>
                  <td>Included</td>
                  <td>Included + Premium Features</td>
                </tr>
              </tbody>
            </table>
          </details>

          <details>
            <summary>How does the process work?</summary>
            <p>
              Our process is simple and efficient:
              <ol>
                <li>We start with a consultation to understand your needs.</li>
                <li>You select a package that fits your goals.</li>
                <li>
                  We design, develop, and deliver your website in as little as
                  one week.
                </li>
                <li>
                  Post-launch, we offer support to ensure everything runs
                  smoothly.
                </li>
              </ol>
            </p>
          </details>

          <details>
            <summary>What industries do you specialize in?</summary>
            <p>
              We’ve worked with a variety of industries, including:
              <ul>
                <li>E-commerce</li>
                <li>Local services</li>
                <li>Nonprofits</li>
                <li>Creative professionals</li>
                <li>Small businesses</li>
              </ul>
              Our goal is to craft a digital presence that works for your unique
              business.
            </p>
          </details>

          <details>
            <summary>Can I upgrade my package later?</summary>
            <p>
              Absolutely! We offer the flexibility to upgrade your package as
              your business grows. Simply reach out, and we’ll help transition
              you to the next tier.
            </p>
          </details>

          <details>
            <summary>Do you provide content creation?</summary>
            <p>
              We can assist with basic content creation for your website,
              including placeholder text, images, and suggestions for
              structuring your site. For detailed content writing or custom
              photography, we can recommend trusted professionals.
            </p>
          </details>

          <details>
            <summary>What if I need custom features?</summary>
            <p>
              We’re happy to discuss custom features and integrations. During
              our consultation, let us know your specific needs, and we’ll
              provide a tailored solution.
            </p>
          </details>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
