import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="page-transition">
      <div className="about-page">
        {/* Hero Section */}
        <div className="hero-section">
          <div className="hero-text">
            <h1>InnoNative Solutions</h1>
            <h2>
              Your Partner in Building Websites That Work as Hard as You Do
            </h2>
            <p className="hero-statement">
              From Montana’s Fort Belknap Reservation to the world, we empower
              small businesses with technology that grows their impact.
            </p>
          </div>
        </div>

        {/* Introduction */}
        <div className="about-section">
          <div className="about-content">
            <h2>About InnoNative Solutions</h2>
            <p>
              At InnoNative Solutions, we’re on a mission to help small
              businesses and communities thrive in the digital age. We deliver
              websites, digital tools, and strategies designed to grow your
              business and maximize your online impact.
            </p>
            <p>
              Founded with a passion for technology and community development,
              we pride ourselves on creating solutions that are not just
              innovative but also meaningful.
            </p>
          </div>
        </div>

        {/* Who We Are */}
        <div className="about-section">
          <div className="about-content">
            <h2>Our Story</h2>
            <p>
              Hi, I’m Charles Werk, a software engineer from the Fort Belknap
              Indian Reservation. I started InnoNative Solutions because I saw a
              need for accessible, high-quality tech services in local
              communities. With a background in software engineering and a deep
              connection to where I’m from, I wanted to create a business that
              helps others succeed while honoring my roots.
            </p>
            <p>
              Innovation, integrity, and a focus on community are at the heart
              of everything we do.
            </p>
          </div>
        </div>

        {/* What We Do */}
        <div className="about-section">
          <div className="about-content">
            <h2>What We Do</h2>
            <p>
              We specialize in:
              <ul>
                <li>Responsive, high-performing websites</li>
                <li>Digital strategies that boost online visibility</li>
                <li>Streamlined tools for small businesses to grow</li>
              </ul>
              Our goal? To make sure your business is positioned to compete,
              connect, and thrive in an ever-changing digital world.
            </p>
          </div>
        </div>

        {/* Who We Serve */}
        <div className="about-section">
          <div className="about-content">
            <h2>Who We Serve</h2>
            <p>
              We work with small businesses, entrepreneurs, and community
              organizations. From Native-owned businesses to local startups, we
              understand the unique challenges you face. That’s why we create
              solutions tailored to your needs.
            </p>
            <p>
              <strong>Success Story:</strong> Read how we helped
              Starquilts247.com double their online sales with a new website
              design and branding.
            </p>
            <a href="/my-work" className="cta-button">
              See Our Work
            </a>
          </div>
        </div>

        {/* Why Choose Us */}
        <div className="about-section">
          <div className="about-content">
            <h2>Why Choose Us</h2>
            <p>
              What sets InnoNative Solutions apart?
              <ul>
                <li>
                  <strong>Community Focus:</strong> We understand your
                  challenges because they’re ours too.
                </li>
                <li>
                  <strong>Tailored Solutions:</strong> We don’t believe in
                  one-size-fits-all. Every project is unique.
                </li>
                <li>
                  <strong>Results-Driven:</strong> Your success is how we
                  measure ours.
                </li>
              </ul>
            </p>
            <p>
              When you work with us, you’re not just getting a website—you’re
              investing in a partner who’s committed to your growth.
            </p>
          </div>
        </div>

        {/* Call to Action */}
        <div className="cta-section">
          <h3>Ready to take your business to the next level?</h3>
          <a href="/contact" className="cta-button">
            Contact us today for a free consultation.
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
