import React from "react";
import "./Hero.css"; // Ensure Hero.css contains the styles below

const Hero = () => {
  return (
    <div className="page-transition">
      <div className="hero">
        <div className="hero-content">
          <h1>Your Business Deserves a Website That Works as Hard as You Do</h1>
          <h2>
            We build stunning, ROI-driven websites for small businesses in just
            one week.
          </h2>

          <ul className="value-proposition">
            <li>
              ✨ <em>Launch Ready:</em> Your site could be live in just 7 days.
            </li>
            <li>
              📱 <em>Looks Great Everywhere:</em> Mobile-friendly designs that
              wow on any device.
            </li>
            <li>
              💡 <em>Built for You:</em> Solutions built to fit small business
              budgets and needs.
            </li>
          </ul>
          <div className="hero-buttons">
            <a
              href="/services"
              className="hero-button primary"
              aria-label="Discover our services"
            >
              Explore Our Services
            </a>
            <a
              href="/contact"
              className="hero-button secondary"
              aria-label="Get a free consultation"
            >
              Book a Free Consultation
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
